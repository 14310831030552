<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64" />
        </v-overlay>
        <v-data-table
            :headers="headers"
            :items="dataTable"
            class="elevation-1"
            :loading="loadingTable"
            :search="search"
            :single-select="false"
            v-model="wesSelected"
            show-select
            item-key="work_effort_id"
            loading-text="Buscando eventos..."
            dense
        >
        <template v-slot:top>
            <v-col cols="12">
                <v-row align="center" justify="center">
                    <v-col cols="12" md="3">
                        <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    class="pt-5"
                                    v-model="dateRangeText"
                                    label="Intervalo de fechas"
                                    persistent-hint
                                    readonly
                                    append-icon="mdi-calendar-multiple"
                                    v-bind="attrs"
                                    v-on="on"
                                    clearable
                                    click:clear="dates=''"
                                />
                            </template>
                            <v-date-picker
                                range
                                v-model="dates"
                                no-title
                            />
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-autocomplete
                            label="Empleado"
                            v-model="partyIdFrom"
                            :items="empleados"
                            dense
                            item-value="party_id"
                            item-text="nombre"
                            hide-details="auto"
                            clearable
                            @change="buscarEventos"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-autocomplete
                            label="Tipo de servicio"
                            v-model="servicioCrmId"
                            :items="servicioCrm"
                            dense
                            item-value="servicio_crm_id"
                            item-text="nombre"
                            hide-details="auto"
                            clearable
                            @change="buscarEventos"
                        />
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field v-model="search" label="Buscar" />
                    </v-col>
                    <v-col cols="12" md="3" class="text-center">
                        <v-btn
                            color="success"
                            dark
                            small
                            @click="openDialogReasignacion"
                        >
                            <v-icon class="me-2">mdi-account-convert</v-icon> Reasignar eventos
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </template>
        <template v-slot:no-results="{ item }">
            No se encontraron eventos
        </template>

        <template v-slot:item.desde="{item}">
            {{moment2(item.desde).format('YYYY-MM-DD HH:mm')}} - {{ moment2(item.hasta).format('HH:mm') }}
        </template>
        </v-data-table>
        <v-dialog 
            v-model="dialogReagendamiento"
            persistent 
            max-width="600px"
        >
            <v-card>
                <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64" />
                </v-overlay>
                <v-toolbar 
                    color="primary" 
                    dense
                    class="white--text"
                >
                    <v-toolbar-title>
                        <v-icon class='mb-1 white--text'>mdi-account-check</v-icon>
                        EMPLEADOS DISPONIBLES
                    </v-toolbar-title>
                </v-toolbar>
                <v-form ref='form_reassign_work_effort'>
                    <v-col>                        
                        <v-autocomplete
                            v-model='partyIdTo'
                            label="Usuarios"
                            :items=empleadosTo
                            dense
                            outlined
                            item-value="party_id"
                            item-text="nombre"
                            :rules=required
                        />
                        <v-row>
                            <v-col class="text-center">
                                <v-btn
                                    color="primary"
                                    @click="reasignarEventos"
                                >
                                    <v-icon>mdi-content-save</v-icon> GUARDAR
                                </v-btn>
                                <v-btn 
                                    color='default'
                                    @click="closeDialogReasignacion"
                                > 
                                    <v-icon>mdi-cancel</v-icon> Cerrar 
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>


<script>

import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import Vue from "vue";

export default {
  name: "ReasignacionEventoComponent",
  data: () => ({
        moment: moment(),
        moment2: moment,
        headers: [
            { text: "ID", value: "work_effort_id" },
            { text: "Servicio", value: "servicio" },
            { text: "Paciente", value: "paciente" },          
            { text: "Fecha", value: "desde" },
            { text: "Estado", value: "state" , sortable:false}
        ],
        dates: [
            moment().format("YYYY-MM-DD"), 
            moment().format("YYYY-MM-DD")
        ],
        required: [(v) => !!v || "El campo es requerido"],
        search:'',
        dataTable:[],
        empleados:[],
        empleadosTo:[],
        servicioCrm:[],
        wesSelected:[],
        overlay: false,
        dialogReagendamiento:false,
        partyIdFrom:null,
        partyIdTo:null,
        servicioCrmId:null,        
    }),
    computed: {

        ...mapState("master", ["loadingTable","tenantId","paramAlertQuestion","titleToolbar"]),

        dateRangeText() {

            return this.dates.join(" ~ ")

        },

    },
    methods: {

        ...mapMutations('master',['setUrl','setLoadingTable','setTitleToolbar','setLoadingBtn','setSeewtNotification']),

        ...mapActions('master',['requestApi','alertNotification']),

        getDataReasignacion(){

            this.setUrl("reasignacion-evento");
            this.overlay = true;

            this.requestApi({
            method: "GET",
                data: {
                    tenantId: this.tenantId,
                    typeList: "datosVistaReasignacion"
                }
            })
            .then(res => {
               
                this.empleados = res.data._embedded.reasignacion_evento[0]
                this.servicioCrm = res.data._embedded.reasignacion_evento[1]
                this.overlay = false

            })
            .catch(() => {

                this.overlay = false

            })
    
        },

        buscarEventos(){

            this.setUrl("reasignacion-evento");
            this.overlay = true;

            this.requestApi({
            method: "GET",
                data: {
                    tenantId: this.tenantId,
                    partyIdFrom : this.partyIdFrom,
                    dates: this.dateRangeText.split('~'),
                    servicioCrmId: this.servicioCrmId,
                    typeList: "eventosEmpleado"
                }
            })
            .then(res => {
               
                this.dataTable = res.data._embedded.reasignacion_evento
                this.overlay = false

            })
            .catch(() => {

                this.overlay = false

            })

        },

        openDialogReasignacion(){

            if(this.partyIdFrom=='' || this.partyIdFrom==null){
                Vue.swal({
                    html: 'Debe escoger un empleado',
                    title: 'Error',
                    icon: 'error',
                    confirmButtonColor: 'red'
                })
                return false
            }

            if(this.servicioCrmId=='' || this.servicioCrmId==null){
                Vue.swal({
                    html: 'Debe escoger un servicio',
                    title: 'Error',
                    icon: 'error',
                    confirmButtonColor: 'red'
                })
                return false
            }

            if(!this.wesSelected.length){
                Vue.swal({
                    html: 'Debe escoger la menos un evento',
                    title: 'Error',
                    icon: 'error',
                    confirmButtonColor: 'red'
                })
                return false
            }

            this.setUrl("reasignacion-evento");
            this.overlay = true

            let sorWe = this.wesSelected.sort((a,b) => new Date(a.desde) - new Date(b.desde))

            let desde = moment(sorWe[0].desde).format('YYYY-MM-DD')
            let hasta = moment(sorWe[sorWe.length-1].hasta).format('YYYY-MM-DD')

            this.requestApi({
                method: "GET",
                data: {
                    tenantId: this.tenantId,
                    desde,
                    hasta,
                    servicioCrmId: this.servicioCrmId,
                    partyIdFrom: this.partyIdFrom,
                    typeList: "empleadosDisponibles"
                }
            })
            .then(res => {
               
                console.log('res',res)
                this.empleadosTo = res.data._embedded.reasignacion_evento
                this.dialogReagendamiento = true
                this.overlay = false

            })
            .catch(() => {

                this.overlay = false

            })

        },

        closeDialogReasignacion(){

            this.partyIdTo = null
            this.wesSelected=[]
            this.dialogReagendamiento = false

        },

        reasignarEventos(){
            
            if(!this.$refs.form_reassign_work_effort.validate())
                return false

            this.overlay = true

            this.requestApi({
                method: "POST",
                data: {
                    tenantId: this.tenantId,
                    partyIdTo: this.partyIdTo,
                    wesSelected: JSON.stringify(this.wesSelected)
                }
            })
            .then(res => {

                try {
                    console.log('res',res)
                   
                    this.setSeewtNotification({timerProgressBar: false})
    
                    const { msgFails, msg } = res.data

                    let msgTotal = `<p>${msg}</p>`
    
                    if(msgFails.length){
    
                        msgTotal += `
                            <p>
                                <ul>
                                    ${
                                        msgFails.map(m => (
                                            `<li list-style:'none'>${m}</li>`
                                        )).join('')
                                    }
                                </ul>
                            </p>
                        `

                    }
    
                    this.alertNotification({
                        param:{
                            icon: msgFails.length ? "warning" : "success",
                            title: msgFails.length ? "Alerta" : "Éxito",
                            html: msgTotal,
                            toast:false,
                            timer: 1300000
                        }
                    })  
                   
                    this.buscarEventos()
                    this.closeDialogReasignacion()
                    this.overlay = false
                    this.setSeewtNotification({timerProgressBar: true})

                } catch (error) {

                    console.log('error',error)
                    
                }
                
            })
            .catch(() => {

                this.overlay = false

            })

        }

    },
    created(){

        this.setTitleToolbar('REASIGNACIÓN DE EVENTOS')
        this.getDataReasignacion()
        
    }
}
</script>